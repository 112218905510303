<template>
    <section class="grid-products-wrap clearfix">

        <div class="container">
<!--            <div class="tab-products-header clearfix">-->
<!--                <ul class="tabs float-left">-->
<!--                    <li-->
<!--                        v-for="(tab, index) in tabs"-->
<!--                        :key="index"-->
<!--                        :class="classes(tab)"-->
<!--                        @click="change(tab)"-->
<!--                    >-->
<!--                        {{ tab.label }}-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->

            <div class="uk-section-large uk-container">
              <div data-uk-scrollspy="target: &gt; *; cls: uk-animation-slide-bottom-medium; delay: 500">


                <div v-for="(productChunks, index) in $chunk(products, 12)" :key="index" class="">

                  <div v-for="product in productChunks" :key="product.id" class="product-category-card">
                    <div class="product-category-card__media"> <img :src="product.picture.path" alt="product-category"></div>
                    <div class="product-category-card__info">
                      <div class="product-category-card__cat">{{product.category_name}}</div>
                      <div class="product-category-card__title">{{product.name}}</div>
                      <div class="product-category-card__intro">{{
                          product.translation.short_description
                        }}</div>
                      <div class="product-category-card__more"><a class="uk-button uk-button-default uk-button-large"  :href="route('products.show', product.slug)">Detail produktu</a></div>
                    </div>
                  </div>



                </div>



            </div>
            </div>

            <flexi-tab
                v-for="(tabLabel, index) in data"
                :key="index"
                :label="tabLabel"
                :url="route('frontend.products_grid.index', { tabId: index + 1 })"
            >
            </flexi-tab>
        </div>
    </section>
</template>

<script>
    import ProductCard from '../ProductCard.vue';
    import DynamicTabsMixin from '../../mixins/FlexiTabMixin';
    import { slickPrevArrow, slickNextArrow } from '../../functions';
    import ProductCardMixin from "../../mixins/ProductCardMixin";

    export default {
        components: { ProductCard },

        mixins: [
            DynamicTabsMixin,  ProductCardMixin,
        ],

        props: ['data'],

        methods: {
            selector() {
                return $('.grid-products');
            },

            slickOptions() {
                return {
                    rows: 0,
                    dots: false,
                    arrows: true,
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rtl: window.App.rtl,
                    prevArrow: slickPrevArrow(),
                    nextArrow: slickNextArrow(),
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                dots: true,
                                arrows: false,
                            },
                        },
                    ],
                };
            },
        },
    };
</script>
