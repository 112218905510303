<template>
  <div>
    <div class="product-card product-card--2">
      <div class="product-card__box">
        <div class="product-card__media"> <img class="product-card__img" :src="picture" alt="" />
          <div class="product-card__btns">
            <ul>
              <li>
                <a class="add-to-cart-btn btn mb-1 mt-2"
                   :class="{ 'btn-loading': cartAddLoading }"
                   tabindex="0"
                   @click="addToBasket"
                   :disabled="product.is_out_of_stock"
                   v-if="noOptions || product.is_out_of_stock">{{ $trans('buy') }}
                </a>


                <a v-else @click="addToBasket"
                      :class="{ 'btn-loading': cartAddLoading }"><span>{{ $trans('buy') }}</span><i class="fas fa-shopping-basket"></i></a></li>

            </ul>
          </div>
        </div>
        <div class="product-card__info">
          <span class="product-prev-price" v-if="product.has_percentage_discount">{{ product.discount_percent }}</span>
          <div class="product-card__title"> <a :href="productUrl">{{ product.name }}</a></div>
          <div class="product-card__price" v-html="product.formatted_price"></div>
          <span class="sold-out-tag position-top-right" v-if="!product.in_stock">{{ $trans('outOfStock') }}</span>

        </div>
      </div>


    </div>
  </div>
</template>

<script>
import ProductRating from './ProductRating.vue';
import ProductCardMixin from '../mixins/ProductCardMixin';

export default {

  components: {ProductRating},

  computed: {

    locale() {
      return window.App.locale;
    }

  },

  mixins: [
    ProductCardMixin,
  ],

  props: ['product', 'classname'],
};
</script>

<style>
.float-left {
  float: left;
}

.la-heart {
  color: #9e9e9e;
}

.la-heart.las {
  color: red;
}

</style>
